import { graphql, Link, PageProps } from "gatsby";
import React from "react";
import { Col, Row } from "react-bootstrap";
import SearchResult from "../../components/SearchResult";
import { videoFromNode } from "../../data/videos";

import Layout from "../../layouts/default";

export const Head = () => (
  <title>
    MandarInput: Comprehensible Input related to Chinese language idioms
  </title>
);

export const IdiomsPage = ({ data }: PageProps<Queries.IdiomVideosQuery>) => {
  const filteredVideos = data.allVideosYaml.edges.map((node) =>
    videoFromNode(node.node)
  );

  return (
    <Layout>
      <Row>
        <Col sm={12} lg={4}>
          <div className="card">
            <h2>Learn Mandarin with Chinese language idioms</h2>
            <p>
              If you&apos;re learning Mandarin it&apos;s useful to listen to
              videos on topics that you are interested in, and suit your
              purposes for learning.
            </p>
            <p>
              These videos all relate to idioms and Chengyu in Chinese. We have{" "}
              <Link to="/">lots of other videos</Link> if you&apos;re interested
              in other topics.
            </p>

            <p>
              Idioms and chengyu are an integral part of the Chinese language
              and culture. An idiom is a phrase or expression that is unique to
              a language, while a chengyu is a four-character phrase that has a
              figurative meaning. Using idioms and chengyu is an important part
              of communicating in Chinese, as they are often used to express
              complex ideas or feelings in a concise manner.
            </p>

            <p>
              For a non-Chinese speaker trying to learn the language,
              understanding idioms and chengyu is critical. Using these
              expressions properly conveys a greater understanding of the
              language and culture, and helps to demonstrate fluency. These
              phrases are also often used in conversation and in literature, so
              it is important to be familiar with them in order to understand
              the context of a conversation or text. Additionally, being able to
              recognize and use these phrases correctly can help to build
              relationships with native Chinese speakers, as it shows that you
              understand the culture and language.
            </p>

            <p>
              Learning these expressions can be challenging for a non-native
              speaker, as there are often multiple interpretations and layers of
              meaning. Additionally, many of these phrases are hundreds of years
              old and do not necessarily make literal sense. However, with some
              practice and exposure, non-Chinese speakers can gain a better
              understanding of these expressions and use them effectively in
              conversations.
            </p>
          </div>
        </Col>
        <Col sm={12} lg={8}>
          {filteredVideos.map(
            (video) =>
              video !== null && (
                <SearchResult
                  key={video.videoId}
                  video={video}
                  viewed={false}
                />
              )
          )}
        </Col>
      </Row>
    </Layout>
  );
};

export default IdiomsPage;

export const pageQuery = graphql`
  query IdiomVideos {
    allVideosYaml(filter: { topics: { in: "idioms" } }) {
      edges {
        node {
          slug
          hsk
          duration
          channel
          topics
          title
          videoId
          subtitles {
            chinese
            english
            pinyin
          }
          speechSpeed
          difficulty
        }
      }
    }
  }
`;
